
import { ButtonContent } from "../global/boton/ButtonContent";
import { GiRibbonMedal } from "react-icons/gi";
import { AiOutlineTeam, AiOutlineHome } from "react-icons/ai";
// import { TbPlant2 } from "react-icons/tb";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <>
      <div className="pic-wrapper svgHerosection1  w-full h-screen">
        {BgImages.map((item, index) => {
          return (
            <figure
              key={index}
              className={`pic-${index + 1}`}
              style={{ background: `url("${item}")` }}
            ></figure>
          );
        })}
        <div className="z-[4] relative w-4/5 mx-auto h-full grid grid-flow-col-1 gijustify-center items-center md:pb-[170px] md:pt-[320px] pt-[300px] pb-[100px]">
          <div className="flex md:flex-row flex-col md:w-4/5 mx-auto md:text-start text-center text-white">
            <div className="w-full md:w-4/5 text-center md:text-start p-4">
              <h1 className="text-[30px] md:text-[3em] lg:text-[62px] font-bold">
                {title}
              </h1>
              <p className="px-5 md:px-0">{texts}</p>
              <ButtonContent btnStyle="three" />
            </div>

            
          </div>

          {/* <div className="flex justify-center items-start md:-mr-32">
            <img className="md:w-[320px] w-[170px]" src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F10%20Years.png?alt=media&token=4a8697b6-cc1e-42e8-9577-7d780cfd2fb8" alt="experience years" />
          </div> */}
        </div>
      </div>
      <div className=" md:w-[70%] w-[100%] bg2 text-white md:-mt-20 -mb-12 -mt-20 relative md:py-14 py-2 homi">
        <ul className="flex md:flex-row flex-col justify-around ml-5 md:ml-0">
          <h5 className="flex items-center" data-aos="zoom-in">
            <AiOutlineHome fontSize={70} className="text-white mr-2" />
            BEST SERVICE
          </h5>
          <h5
            className="flex items-center"
            data-aos="zoom-in"
            data-aos-duration="1000"
          >
            <AiOutlineTeam fontSize={70} className="text-white" />
            PROFESSIONAL TEAM
          </h5>
          <h5
            className="flex items-center"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <GiRibbonMedal fontSize={70} className="text-white" />
            BEST QUALITY
          </h5>
        </ul>
      </div>
    </>
  );
}

export default HeroSection;
